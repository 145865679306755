import React from 'react'
import ReactDOM from 'react-dom/client'
import { DevSupport } from '@react-buddy/ide-toolbox' //для разработки
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd' //предоставляет глобальную настройку для всех компонентов Ant Design, например, тему.
import { theme } from '@src/theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ComponentPreviews, useInitial } from './dev' //компоненты и хук для поддержки разработки.
import { store } from './store/store'
import './style/normalize.css'
import './style/index.scss'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={theme}>
        <Provider store={store}>
          <DevSupport
            ComponentPreviews={ComponentPreviews}
            useInitialHook={useInitial}
          >
            <App />
          </DevSupport>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
)

//If you want to start measuring performance in your app, pass a function
//to log results (for example: reportWebVitals(console.log))
//or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

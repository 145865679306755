import { FC, useMemo, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/store/services/auth-service'
import { setPhoneNumber, setUserId } from '@src/store/ducks/app/reducer'
import { Button } from 'antd'
import { SendOutlined, UserAddOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AuthContainer } from '@src/components/Auth/AuthContainer/AuthContainer'
import style from './authorization.module.scss'

export const Authorization: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [authorization, { isLoading }] = authApi.useAuthorizationMutation()
  const history = useHistory()
  const [error, setError] = useState<string|null>(null)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^\+375\d{9}$/, 'Некорректный номер')
        .length(13, 'Некорректный номер')
        .required('Обязательное поле'),
    })
  ), [])

  const initialValues = useMemo(() => ({
    phone: '',
  }), [])

  const onSubmit = async (value: typeof initialValues) => {
    console.log('Submitting form:', value)
    const result = await authorization(value)

    //Это просто бред какой-то с типами данных, но я просто не смог достать иначе текст ошибки
    if ('error' in result) {
      console.log('init1')
      if ('data' in result.error) {
        if (result.error.data && typeof result.error.data === 'object' && 'error' in result.error.data) {
          const errorMessage = result.error.data.error
          if (errorMessage && typeof errorMessage === 'string') {
            console.log('errorMessage', errorMessage);
            setError(errorMessage)
          }
        }
      }
    }

    //if ('data' in result && result.data?.success) {
    if ('data' in result) {
      console.log('data:', result.data)
      const { user_id, nameRequired } = result.data
      dispatch(setPhoneNumber(value.phone))
      user_id && dispatch(setUserId(result.data.user_id))
      history.push(nameRequired ? '/advanced-authorization' : '/login')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>, submitForm: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitForm()
    }
  }

  return (
    <AuthContainer title="Авторизация" showBackBtn>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <Form onKeyDown={(event) => handleKeyDown(event, submitForm)}>
            <FieldFormik type="phone" name="phone" placeholder="+375 (XX) XXX-XX-XX" fieldTitle="Ваш номер телефона" />

            {error && <div className={style.errorContainer}>{error}</div>}

            <div className={style.actions}>
              <Button type="primary" htmlType="submit" size="large" className="btn__login" icon={<SendOutlined />} loading={isLoading}>
                Авторизироваться
              </Button>

              <div className={style.subtitle}>
                У Вас еще нету аккаунта?
              </div>

              <NavLink to="/registration">
                <Button type="link" size="large" className="btn__link" icon={<UserAddOutlined />}>Регистрация</Button>
              </NavLink>

              <div className={style.support}>
                <span className={style.support__title}>Проблемы с авторизацией?</span>
                <span className={style.support__info}>
                  Свяжитесь по номеру
                  <a className="main-link main-link--full-green" href="tel:+375296661177">+375 (29) 666 11 77</a>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  )
}

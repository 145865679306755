export function formatPhoneNumber(input: string): string {
  const cleaned = input.replace(/\D/g, '')
  //mask format: +375 (29) 0000000
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{7})$/)

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}`
  }

  return input
}

export function formatCurrency(value: string | number | undefined, currencyLabel?: string): string {
  //Проверяем, является ли value адекватной строкой или числом
  if (value === null || value === undefined || value === '' || value === 'null' || value === 'undefined') {
    return '-'
  }

  //Преобразуем значение в строку и форматируем его
  const formattedValue = typeof value === 'number'
    ? value.toFixed(2).replace('.', ',') //Преобразуем число в строку с двумя знаками после запятой
    : value.replace('.', ',')

  //Добавляем пробелы после каждой третьей цифры перед запятой
  const formattedWithSpaces = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  //Возвращаем отформатированное значение с добавлением метки валюты, если она предоставлена
  return currencyLabel ? `${formattedWithSpaces} ${currencyLabel}` : formattedWithSpaces
}

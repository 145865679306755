import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  RegistrationRequest,
  RegistrationResponse,
  LoginResponse,
  LoginRequest,
  AuthorizationResponse, AuthorizationRequest,
} from '@src/types/auth'
import { setIsInitialized } from '@src/store/ducks/app/reducer'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    authorization: build.mutation<AuthorizationResponse, AuthorizationRequest>({
      query: (body) => ({
        url: '/authorization',
        method: 'POST',
        body,
      }),
    }),
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    registration: build.mutation<RegistrationResponse, RegistrationRequest>({
      query: (body) => ({
        url: '/registration',
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          console.error('Error:', error)
        } finally {
          dispatch(setIsInitialized())
        }
      },
    }),
  }),
})

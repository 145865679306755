import { Button } from 'antd'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { FC } from 'react'
import style from './backBtn.module.scss'

type BackBtnProps = {
  text?: string;
  directLink?: string;
  className?: string;
};

export const BackBtn: FC<BackBtnProps> = ({ text = 'Вернуться назад', directLink, className }) => {
  const history = useHistory()

  const handleBack = () => {
    if (directLink) {
      window.location.href = directLink
    } else {
      history.goBack()
    }
  }

  return (
    <Button
      onClick={handleBack}
      type="primary"
      size="large"
      className={cn(style.backBtn, className, 'btn__secondary')}
      icon={<ArrowLeftOutlined />}
    >
      {text}
    </Button>
  )
}

export default BackBtn

import { Redirect, Route, Switch } from 'react-router-dom'
import { Authorization } from '@src/Pages/Authorization/Authorization'
import { Registration } from '@src/Pages/Registration/Registration'
import { RegistrationData } from '@src/Pages/RegistrationData/RegistrationData'
import { Login } from '@src/Pages/Login/Login'
import { AdvancedAuthorization } from '@src/Pages/AdvancedAuthorization/AdvancedAuthorization'
import { Orders } from '@src/Pages/Orders/Orders'
import { OrderDetail } from '@src/Pages/OrderDetail/OrderDetail'
import { OrderInstallments } from '@src/Pages/OrderInstallments/OrderInstallments'
import { OrderInstallmentsStages } from '@src/Pages/OrderInstallmentsStages/OrderInstallmentsStages'
import { Profile } from '@src/Pages/Profile/Profile'
import { EditProfile } from '@src/Pages/EditProfile/EditProfile'
import { Page404 } from '@src/Pages/404/404'
import React from 'react'
import { selectAppIsAuth, selectAppPhoneNumber } from '@src/store/ducks/app/selectors'
import { useSelector } from 'react-redux'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

export const Routes = () => {
  const authorizationPhone = useSelector(selectAppPhoneNumber)

  return (
    <Switch>
      <PublicRoute path="/" exact>
        <Authorization />
      </PublicRoute>

      <PublicRoute path="/authorization" exact>
        <Authorization />
      </PublicRoute>

      <PublicRoute path="/advanced-authorization" exact>
        {authorizationPhone ? <AdvancedAuthorization /> : <Redirect to="/authorization" />}
      </PublicRoute>

      <PublicRoute path="/registration" exact>
        <Registration />
      </PublicRoute>

      <PublicRoute path="/login" exact>
        {authorizationPhone ? <Login /> : <Redirect to="/authorization" />}
      </PublicRoute>

      <PrivateRoute path="/сlarify-user-info" exact>
        <RegistrationData />
      </PrivateRoute>

      <PrivateRoute path="/profile" exact>
        <Profile />
      </PrivateRoute>

      <PrivateRoute path="/edit-profile" exact>
        <EditProfile />
      </PrivateRoute>

      <PrivateRoute path="/orders" exact>
        <Orders />
      </PrivateRoute>

      <PrivateRoute path="/orders/:id" exact>
        <OrderDetail />
      </PrivateRoute>

      <PrivateRoute path="/installments/:id" exact>
        <OrderInstallments />
      </PrivateRoute>

      <PrivateRoute path="/installments-stages/:id" exact>
        <OrderInstallmentsStages />
      </PrivateRoute>

      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  )
}

import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setIsPhoneChanged } from '@src/store/ducks/app/reducer'
import { selectAppIsPhoneChanged, selectAppUser } from '@src/store/ducks/app/selectors'
import { userApi } from '@src/store/services/user-service'
import { Button, Alert } from 'antd'
import { MailOutlined, PhoneOutlined, UserOutlined, SaveOutlined, UndoOutlined, EditOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import * as Yup from 'yup'
import { formatPhoneNumber } from '@src/utils/utils'
import { PageHeader } from '@src/components/PageHeader/PageHeader'
import style from './editProfile.module.scss'

const pageHeaderData = {
  title: 'Редактирование данных',
  description: 'Если необходимо дополнительное текстовое пояснение',
}

const userColumnEditTitles: Record<string, string> = {
  Email: 'Изменить электронную почту',
  Client: 'Изменить ФИО',
  PhoneLK: 'Текущий номер телефона',
}

const getFieldIcon = (key: string) => {
  switch (key) {
    case 'Email':
      return <MailOutlined />
    case 'PhoneLK':
      return <PhoneOutlined />
    default:
      return <UserOutlined />
  }
}

const getFieldEditTitle = (key: string) => userColumnEditTitles[`${key}`]

export const EditProfile = () => {
  const [update, { isLoading: isUpdateLoading }] = userApi.useUpdateMutation()
  const [updatePhone, { isLoading: isPhoneUpdateLoading }] = userApi.useUpdatePhoneMutation()
  const user = useSelector(selectAppUser)
  const dispatch = useDispatch()
  const isPhoneChanged = useSelector(selectAppIsPhoneChanged)
  const history = useHistory()

  const editInfoInitialValues = useMemo(() => ({
    name: user.Client,
    email: user.Email,
  }), [user])

  const editPhoneInitialValues = useMemo(() => ({
    oldPhone: formatPhoneNumber(String(user.PhoneLK)),
    phone: '',
  }), [user])

  const editInfoValidationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
      email: Yup.string().email('Некорректный email').required('Обязательное поле'),
    })
  ), [])

  const editPhoneValidationSchema = useMemo(() => (
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^\+375\d{9}$/, 'Некорректный номер')
        .length(13, 'Некорректный номер')
        .required('Обязательное поле'),
    })
  ), [])

  const onSubmitEditInfo = async (value: typeof editInfoInitialValues) => {
    const result = await update(value)
  }

  const onSubmitEditPhone = async (value: typeof editPhoneInitialValues) => {
    const { oldPhone, ...requetBody } = value
    const result = await updatePhone(requetBody)
    if ('data' in result) {
      localStorage.setItem('phoneRequest', 'sent')
      dispatch(setIsPhoneChanged(true))
    }
  }

  return (
    <div className={style.editProfile__wrapper}>
      <PageHeader title={pageHeaderData.title} description={pageHeaderData.description} showBackBtn />
      <Formik
        initialValues={editInfoInitialValues}
        onSubmit={onSubmitEditInfo}
        validationSchema={editInfoValidationSchema}
        enableReinitialize
      >
        {({ resetForm }) => (
          <Form>
            <div className={style.editProfile__items}>
              <FieldFormik name="email" placeholder="mail@mail.by" fieldTitle={getFieldEditTitle('Email')} fieldTitleIcon={getFieldIcon('Email')} />
              <FieldFormik name="name" placeholder="ФИО" fieldTitle={getFieldEditTitle('Client')} fieldTitleIcon={getFieldIcon('Client')} />
            </div>
            <div className={style.editProfile__btns}>
              <Button htmlType="submit" type="primary" size="large" className="btn__main" icon={<SaveOutlined />} loading={isUpdateLoading}>Сохранить</Button>
              {/*<Button onClick={() => resetForm()} type="primary" size="large" danger icon={<UndoOutlined />}>Отменить</Button>*/}
              <Button onClick={() => history.goBack()} type="primary" size="large" danger icon={<UndoOutlined />}>Отменить</Button>
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={editPhoneInitialValues}
        onSubmit={onSubmitEditPhone}
        validationSchema={editPhoneValidationSchema}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className={style.editProfile__items}>
              <FieldFormik type="input" name="oldPhone" fieldTitle="Текущий номер телефона" readonly disabled fieldTitleIcon={getFieldIcon('PhoneLK')} />
              {!isPhoneChanged && (
                <FieldFormik type="phone" name="phone" placeholder="+375 (XX) XXX-XX-XX" fieldTitle="Новый номер телефона" fieldTitleIcon={getFieldIcon('PhoneLK')} />
              )}
            </div>
            <div className={style.editProfile__btns}>
              {isPhoneChanged ? (
                <Alert
                  description="Запрос на смену номера отправлен. Наш менеджер свяжется с Вами в рабочее время."
                  type="success"
                />
              ) : (
                <Button htmlType="submit" type="primary" size="large" className="btn__secondary btn__secondary_greenBorder" icon={<EditOutlined />} loading={isPhoneUpdateLoading}>
                  Запрос смены
                  номера
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

import {
  FC, ForwardRefExoticComponent, useCallback, useMemo, useState,
  ReactNode,
} from 'react'
import { Input } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import ReactPhoneInput from 'react-phone-input-2'
import './react-phone-input-2.css'
import style from './FieldFormik.module.scss'

type FieldFormikProps = {
  name: string
  Prefix?: ForwardRefExoticComponent<any>
  placeholder?: string
  type?: string
  required?: boolean
  styleInline?: object
  autoSize?: {
    minRows: number
    maxRows: number
  }
  addonBefore?: ReactNode
  autoFocus?: boolean
  disabled?: boolean
  readonly?: boolean
  autoComplete?: string
  onChange?: (e: any) => void
  fieldTitle?: string
  fieldTitleIcon?: ReactNode
  inputTextCentered?: boolean
  maxLength?: number
  allowOnlyNumbers?: boolean
  mask?: string
}

export const FieldFormik: FC<FieldFormikProps> = ({
  Prefix,
  autoComplete,
  readonly,
  disabled,
  autoFocus,
  addonBefore,
  styleInline,
  placeholder,
  name,
  type = 'text',
  required,
  autoSize,
  onChange,
  fieldTitleIcon,
  fieldTitle,
  inputTextCentered,
  maxLength,
  allowOnlyNumbers,
  mask,
}) => {
  const [field, meta] = useField(name)
  const { setFieldValue } = useFormikContext<any>()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleBlur = useCallback((e: any) => {
    setIsFocused(false)
    field.onBlur(e)
  }, [field])

  //const handleChange = useCallback((e: any) => {
  //field.onChange(e)
  //
  //if (onChange) {
  //onChange(e)
  //}
  //}, [field, onChange])

  const handleChange = useCallback((e: any) => {
    let { value } = e.target

    if (allowOnlyNumbers) {
      value = value.replace(/[^0-9]/g, '')
    }

    if (maxLength && value.length <= maxLength) {
      setFieldValue(name, value)
    } else if (!maxLength) {
      setFieldValue(name, value)
    }

    if (onChange) {
      onChange(value)
    }
  }, [field, onChange, allowOnlyNumbers, setFieldValue])
  //}, [field, maxLength, name, onChange, allowOnlyNumbers, setFieldValue])

  //const fieldTitleComponent = () => <div className={style['field-title']}>{fieldTitle}</div>
  const fieldTitleComponent = () => (
    <span className={style['field-title']}>
      {fieldTitleIcon ?? ''}
      {fieldTitle ?? ''}
    </span>
  )

  const fieldComponent = useMemo(
    () => {
      switch (type) {
        case 'password':
          return (

            <Input.Password
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              size="large"
            />
          )
        case 'textarea':
          return (
            <Input.TextArea
              {...field}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              autoSize={autoSize}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
            />
          )
        case 'phone':
          return (
            <ReactPhoneInput
              country="by"
             //preferredCountries={[]}
              onChange={(value) => {
                const formattedValue = value.startsWith('+') ? value : `+${value}`
                setFieldValue(name, formattedValue.replace(/[^\d+]/g, ''))
              }}
              countryCodeEditable={false}
            />
          )
        default:
          return mask ? (
            <MaskedInput
              mask={mask}
              value={field.value}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled || readonly}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              size="large"
              maxLength={type === 'text' && maxLength ? maxLength : undefined}
            />
          ) : (
            <Input
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              onChange={handleChange}
              size="large"
              maxLength={maxLength || undefined}
            />
          )
      }
    },
    [Prefix, addonBefore, autoComplete, autoFocus, autoSize, disabled, field, handleBlur, handleChange,
      handleFocus, isError, name, readonly, setFieldValue, styleInline, type],
  )

  return (
    <div className={cn(style.wrapper, fieldTitle && style['wrapper__with-field-title'], inputTextCentered && style['wrapper__text-input-centered'])}>
      {/*eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
      <label className={cn(style.label, readonly && !disabled && style.readonly, type === 'phone' && style.phone)}>
        {(fieldTitleIcon || fieldTitle) && fieldTitleComponent()}
        <span className={
          cn(
            style.placeholder,
            type === 'textarea' && style.placeholder__textarea,
            Prefix && style.placeholder_icon,
            meta.value && style.placeholder_active,
            type === 'phone' && style.placeholder__phone,
          )
        }
        >
          {placeholder}
          {required && <span className={style.placeholder_required}>*</span>}
        </span>
        {fieldComponent}

        {isError && <span className={style.error}>{meta.error}</span>}
      </label>
    </div>

  )
}

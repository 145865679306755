import { FC, ReactNode } from 'react'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import { Header } from '@src/components/Header/Header'
import { selectAppIsAuth, selectNeedClarifyUserData } from '@src/store/ducks/app/selectors'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import style from './pageContainer.module.scss'

type PageContainerProps = {
  children: ReactNode
  className?: string
}

export const PageContainer: FC<PageContainerProps> = ({ children, className }) => {
  const isAuth = useSelector(selectAppIsAuth)
  const location = useLocation<{ isRegistration?: boolean }>()
  const isRegistration = location.state?.isRegistration || false
  //const needClarifyUserData = useSelector(selectNeedClarifyUserData)

  return (
    <Layout>
      {/*{isAuth && !isRegistration && !needClarifyUserData && <Header />}*/}
      {isAuth && !isRegistration && <Header />}

      {/*<Layout.Content*/}
      {/*className={isAuth*/}
      {/*? cn(style.main, { [style.mainNoPading]: isHiddenMenu })*/}
      {/*: undefined}*/}
      {/*style={!isAuth*/}
      {/*? { margin: '0px', overflow: 'initial', background: '#fff' }*/}
      {/*: undefined}*/}
      {/*>*/}
      <Layout.Content
        className={style.main}
      >
        {children}
      </Layout.Content>
    </Layout>
  )
}

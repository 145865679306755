import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authApi } from '@src/store/services/auth-service'
import { userApi } from '@src/store/services/user-service'
//import { logoutAC, resetStore } from '@src/store/ducks/app/reducer'
import { logoutAC, setIsAuth } from '@src/store/ducks/app/reducer'
import { Button } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import cn from 'classnames'
import style from '@src/components/Menu/menu.module.scss'
import { selectAppIsAuth } from '@src/store/ducks/app/selectors'
import { useHistory } from 'react-router-dom'

type LogOutBtnProps = {
  className?: string
}

export const LogOutBtn: FC<LogOutBtnProps> = ({ className }) => {
  const dispatch = useDispatch()
  const [logout] = authApi.useLogoutMutation()
  const isAuth = useSelector(selectAppIsAuth)
  const history = useHistory()

  //const handleLogout = () => {
  //console.log('handleLogout FIRED')
  //logout()
  //dispatch(userApi.util.resetApiState())
  //localStorage.removeItem('token')
  //localStorage.removeItem('phoneRequest')
  //dispatch(setIsAuth(false))
  //}

  const handleLogout = () => {
    logout()
    dispatch(userApi.util.resetApiState())
    dispatch(setIsAuth(false))
    dispatch(logoutAC())
    history.push('/authorization')
  }

  return (
    <Button type="link" size="large" className={cn(className, 'btn__link')} onClick={handleLogout} icon={<LogoutOutlined />}>
      Выйти из аккаунта
    </Button>
  )
}

import { useMemo, useEffect, useCallback, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectAppIsInitialized } from '@src/store/ducks/app/selectors'
import { setIsLoading } from '@src/store/ducks/app/reducer'
import { Button, Table, TablePaginationConfig } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { PageHeader } from '@src/components/PageHeader/PageHeader'
import { ordersApi } from '@src/store/services/orders-service'
import { InstallmentListItemType } from '@src/types/order'
import { StatusBadge } from '@src/components/StatusBadge/StatusBadge'
import { formatCurrency } from '@src/utils/utils'
import cn from 'classnames'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface'
import { OrderListItemType } from '@src/types/orders'
import useQuery from '@src/hooks/useQuery'
import style from './OrderInstallments.module.scss'

const pageHeaderData = {
  title: 'Рассрочка',
  description: 'Заказ №',
}

export const OrderInstallments = () => {
  const params = useParams<{ id: string }>()
  const location = useLocation<{ contractNumber: string }>()
  const history = useHistory()
  const contractNumber = location.state?.contractNumber
  const { data: response, status } = ordersApi.useOrderInstallmentsQuery(Number(params.id))
  const [dataSource, setDataSource] = useState<InstallmentListItemType[]>([])
  const dispatch = useDispatch()
  const isInitialized = useSelector(selectAppIsInitialized)
  const pageQuery = useQuery('page')
  const [filters, setFilters] = useState<{ page: number }>({
    page: pageQuery ? +pageQuery : 1,
  })

  useEffect(() => {
    if (status === 'fulfilled' && response) {
      console.log('Fetched orders data:', response)
      setDataSource(response.data.data)
    } else if (status === 'pending') {
      //setDataSource([]) //Очистка данных при ожидании (опционально)
    }
  }, [status, response])

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      setFilters((prev) => ({
        ...prev,
        page: pagination.current || 1,
      }))
    },
    [],
  )

  const renderMobileCard = useCallback((record: InstallmentListItemType) => (
    <div className={style.mobileCard}>
      <div className={style.mobileCard__header}>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <span>Дата оплаты</span>
          <span>
            <strong>{record.PaymentDateP ? new Date(record.PaymentDateP).toLocaleDateString() : 'Нет данных'}</strong>
          </span>
        </div>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <StatusBadge installmentNumbers={[record.PaymentAmountP, record.Dolg]} type="installment" />
        </div>
      </div>
      <div className={style.mobileCard__main}>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <span>Сумма к оплате</span>
          <span>
            <strong>{formatCurrency(record.PaymentAmountP, 'руб')}</strong>
          </span>
        </div>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <span>Остаток оплаты</span>
          <span>
            <strong>{formatCurrency(record.Dolg, 'руб')}</strong>
          </span>
        </div>
      </div>
    </div>
  ), [history])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: 'Дата оплаты',
      dataIndex: 'PaymentDateP',
      key: 'PaymentDateP',
      width: 140,
      align: 'center',
      responsive: ['sm'],
      render: (PaymentDateP) => new Date(PaymentDateP).toLocaleDateString(),
    },
    {
      title: 'Статус заказа',
      key: 'status',
      width: 140,
      align: 'center',
      responsive: ['sm'],
      render: (record) => <StatusBadge installmentNumbers={[record.PaymentAmountP, record.Dolg]} type="installment" />,
    },
    {
      title: 'Сумма к оплате',
      dataIndex: 'PaymentAmountP',
      key: 'PaymentAmountP',
      width: 140,
      align: 'center',
      responsive: ['sm'],
      render: (PaymentAmountP) => formatCurrency(PaymentAmountP),
    },
    {
      title: 'Остаток оплаты',
      dataIndex: 'Dolg',
      key: 'Dolg',
      width: 140,
      align: 'center',
      responsive: ['sm'],
      render: (Dolg) => formatCurrency(Dolg),
    },
    {
      title: 'Мобильный вид',
      key: 'mobile',
      responsive: ['xs'],
      render: (record) => renderMobileCard(record),
    },
  ], [history])

  return (
    <div className={style.orderDetail__wrapper}>
      <PageHeader title={pageHeaderData.title} description={`${pageHeaderData.description}${contractNumber}`} showBackBtn />
      <Table<InstallmentListItemType>
        //dataSource={response?.data.products}
        dataSource={dataSource}
        columns={columns}
        rowKey="ID"
        loading={status === 'pending'}
        className={style.table}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
        //pagination={{
        //total: response?.data.total,
        //current: filters.page,
        //pageSize: response?.data.per_page,
        //showSizeChanger: false,
        //}}
        pagination={{
          total: response?.data.total ?? 0,
          current: filters.page,
          pageSize: response?.data.per_page ?? 10,
          showSizeChanger: false,
          hideOnSinglePage: !response?.data.next_page_url,
        }}
      />
    </div>
  )
}

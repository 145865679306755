import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { setUser, logoutAC, setIsAuth, setPhoneNumber } from '@src/store/ducks/app/reducer'
import { UpdatePhoneUserRequest, UpdateUserRequest, UserResponse, UserType } from '@src/types/user'
import { useHistory } from 'react-router-dom'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    method: 'POST',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  tagTypes: ['User'],
  endpoints: (build) => ({
    me: build.query<UserResponse, void>({
      query: () => ({
        url: '/user',
        //method: 'POST',
      }),
      providesTags: () => ['User'],
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const userData = data.data
          dispatch(setUser(userData))
        } catch (error: any) {
          console.error('Error fetching user:', error)
          if (error?.status === 401) {
            dispatch(userApi.util.resetApiState())
            dispatch(setIsAuth(false))
            dispatch(setPhoneNumber(''))
            localStorage.removeItem('token')
            localStorage.removeItem('phoneRequest')
          }
        }
      },
    }),
    update: build.mutation<void, UpdateUserRequest>({
      query: (body) => ({
        url: '/user/update',
        //method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    updatePhone: build.mutation<void, UpdatePhoneUserRequest>({
      query: (body) => ({
        url: '/user/updatePhone',
        //method: 'POST',
        body,
      }),
      //invalidatesTags: ['User'],
    }),
    delete: build.mutation<void, void>({
      query: (body) => ({
        url: '/user/delete',
        //method: 'POST',
        body,
      }),
    }),
  }),
})

import { RootState } from '@src/store/store'
import { AppStateType, initialState } from './reducer'

export const selectApp = (state: RootState): AppStateType => state.app || initialState
export const selectAppIsAuth = (state: RootState): boolean => selectApp(state).isAuth
export const selectNeedClarifyUserData = (state: RootState) : boolean => selectApp(state).needClarifyUserData
export const selectAppUser = (state: RootState): typeof initialState.user => selectApp(state).user
export const selectAppIsPhoneChanged = (state: RootState): boolean => selectApp(state).isPhoneChanged
export const selectAppPhoneNumber = (state: RootState): string => selectApp(state).phoneNumber
export const selectAppUserId = (state: RootState): number => selectApp(state).userId
export const selectAppIsInitialized = (state: RootState): boolean => selectApp(state).isInitialized

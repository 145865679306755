import { FC, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/store/services/auth-service'
import { userApi } from '@src/store/services/user-service'
import { setIsAuth, setPhoneNumber, setUserId } from '@src/store/ducks/app/reducer'
import { selectAppIsAuth, selectAppUser } from '@src/store/ducks/app/selectors'
import { Button } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import useQuery from '@src/hooks/useQuery'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AuthContainer } from '@src/components/Auth/AuthContainer/AuthContainer'

import style from './registrationData.module.scss'

type RegistrationProps = {
  isRegister?: boolean
}

export const RegistrationData: FC<RegistrationProps> = ({ isRegister }) => {
  console.log('RegistrationData PAGE')
  const [update, { isLoading }] = userApi.useUpdateMutation()
  const user = useSelector(selectAppUser)
  const history = useHistory()
  console.log('user:', user)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
      email: Yup.string().email('Некорректный email').required('Обязательное поле'),
    })
  ), [])

  const initialValues = useMemo(() => ({
    name: user.Client,
    email: user.Email,
  }), [user])

  const onSubmit = async (value: typeof initialValues) => {
    const result = await update(value)

    //setTimeout(() => {
    //history.replace('/orders')
    //}, 1500)
    if ('data' in result) {
      history.replace('/orders')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>, submitForm: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitForm()
    }
  }

  return (
    <AuthContainer title="Уточнение данных" pageClass={style.authContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form onKeyDown={(event) => handleKeyDown(event, submitForm)}>
            <FieldFormik name="name" placeholder="ФИО" fieldTitle="Ваше ФИО" />
            <FieldFormik name="email" placeholder="mail@mail.by" fieldTitle="Электронная почта" />

            <div className={style.actions}>
              <Button type="primary" htmlType="submit" size="large" className="btn__login" icon={<SendOutlined />} loading={isLoading}>
                Сохранить данные
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  )
}

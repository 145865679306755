import { useMemo, useEffect, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectAppIsInitialized } from '@src/store/ducks/app/selectors'
import { setIsLoading } from '@src/store/ducks/app/reducer'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { PageHeader } from '@src/components/PageHeader/PageHeader'
import { ordersApi } from '@src/store/services/orders-service'
import { ProductType, PaymentType } from '@src/types/order'
import { formatCurrency } from '@src/utils/utils'
import cn from 'classnames'
import style from './OrderDetail.module.scss'

const pageHeaderData = {
  title: 'Заказ #',
  description: 'Если необходимо дополнительное текстовое пояснение',
}

function getUniqueVidPayments(payments: { VidPayment: string }[] | undefined): string {
  if (!payments || payments.length === 0) return ''
  const uniquePayments = Array.from(new Set(payments.map((payment) => payment.VidPayment)))
  return uniquePayments.join(', ')
}

function getTotalDiscount(products: ProductType[] | undefined): number {
  if (!products || products.length === 0) return 0
  return products.reduce((total, product) => total + (product.Discount || 0), 0)
}

export const OrderDetail = () => {
  const params = useParams<{ id: string }>()
  const location = useLocation<{ orderNumber: string }>()
  //const orderNumber = location.state?.orderNumber
  const { data: response, status } = ordersApi.useOrderDetailQuery(Number(params.id))
  const dispatch = useDispatch()
  const isInitialized = useSelector(selectAppIsInitialized)

  useEffect(() => {
    console.log('OrderDetail query status:', status) //Логируем статус запроса
    if (status === 'pending') {
      dispatch(setIsLoading())
    }
  }, [isInitialized, dispatch])

  useEffect(() => {
    if (status === 'fulfilled' && response) {
      console.log('Fetched orders data:', response)
    } else if (status === 'pending') {
      //setDataSource([]) //Очистка данных при ожидании (опционально)
    }
  }, [status, response])

  const renderMobileCard = useCallback((record: ProductType) => (
    <div className={style.mobileCard}>
      <div className={style.mobileCard__header}>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <span>
            <strong>{record.Product}</strong>
          </span>
        </div>
      </div>
      <div className={style.mobileCard__main}>
        <div className={style.mobileCard__item}>
          <span>Кол-во</span>
          <span>
            <strong>{record.Quantity}</strong>
          </span>
        </div>
        <div className={style.mobileCard__item}>
          <span>Общая стоимость</span>
          <span>
            <strong>{formatCurrency(record.Amount, 'руб')}</strong>
          </span>
        </div>
      </div>
    </div>
  ), [history])

  const columns = useMemo<ColumnProps<ProductType>[]>(() => [
    {
      title: 'Перечень товаров',
      dataIndex: 'Product',
      key: 'Product',
      responsive: ['sm'],
    },
    {
      title: 'Количество',
      dataIndex: 'Quantity',
      key: 'Quantity',
      width: 110,
      align: 'center',
      responsive: ['sm'],
    },
    {
      title: 'Общая стоимость',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 160,
      align: 'center',
      responsive: ['sm'],
      render: (Amount) => formatCurrency(Amount),
    },
    {
      title: 'Мобильный вид',
      key: 'mobile',
      responsive: ['xs'],
      render: (record) => renderMobileCard(record),
    },
  ], [history])

  return (
    <div className={style.orderDetail__wrapper}>
      <PageHeader title={`${pageHeaderData.title}${response?.data.ContractNumber}`} description={pageHeaderData.description} showBackBtn />
      <Table<ProductType>
        dataSource={response?.data.products}
        columns={columns}
        rowKey="ID"
        loading={status === 'pending'}
        className={style.table}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
      <div className={style.orderInfo}>
        <div className={style.orderInfo__columns}>
          <div className={style.infoItem}>
            <div className={style.infoItem__label}>
              Способ оплаты
            </div>
            <div className={style.infoItem__value}>
              {/*{response?.data.payments[0]?.VidPayment}*/}
              {getUniqueVidPayments(response?.data.payments) || '-'}
            </div>
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItem__label}>
              Статус оплаты
            </div>
            <div className={style.infoItem__value}>
              {response?.data.StatusOpl}
            </div>
          </div>
        </div>
        <div className={style.orderInfo__columns}>
          <div className={style.infoItem}>
            <div className={style.infoItem__label}>
              Адрес доставки
            </div>
            <div className={style.infoItem__value}>
              {response?.data.ShipAddress}
            </div>
          </div>
        </div>
        <div className={style.amountInfo}>
          <div className={style.amountInfo__general}>
            <div className={style.amountInfo__generalTitle}>
              Общая сумма заказа
            </div>
            <div className={style.amountInfo__generalValue}>
              {formatCurrency(response?.data.AmountWithNds)}
            </div>
          </div>
          <div className={style.amountInfo__additional}>
            <div className={style.amountInfoItem}>
              <div className={style.amountInfoItem__title}>
                Стоимость товаров
              </div>
              <div className={style.amountInfoItem__value}>
                {/*{formatCurrency((response?.data.AmountWithNds || 0) - (response?.data.DopOplTrans || 0) + getTotalDiscount(response?.data.products))}*/}
                {formatCurrency((response?.data.AmountWithNds || 0) - (response?.data.DopOplTrans || 0))}
                <span>р.</span>
              </div>
            </div>
            <div className={style.amountInfoItem}>
              <div className={style.amountInfoItem__title}>
                Стоимость доставки
              </div>
              <div className={style.amountInfoItem__value}>
                {formatCurrency(response?.data.DopOplTrans)}
                <span>р.</span>
              </div>
            </div>
            {/*<div className={style.amountInfoItem}>*/}
            {/*<div className={style.amountInfoItem__title}>*/}
            {/*Примененные скидки*/}
            {/*</div>*/}
            {/*<div className={style.amountInfoItem__value}>*/}
            {/*<strong>{formatCurrency(getTotalDiscount(response?.data.products))}</strong>*/}
            {/*<span>р.</span>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

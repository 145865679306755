import { useEffect, useState } from 'react'
import { Drawer, Layout } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import { UserOutlined, LogoutOutlined, UserAddOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { Menu } from '@src/components/Menu/Menu'
import { ReactComponent as Burger } from '@src/assets/icons/hamburge-i.svg'
import { ReactComponent as CloseHamburgerMenuBtn } from '@src/assets/icons/hamburger-close-i.svg'
import logo from '@src/assets/logo.png'
import { LogOutBtn } from '@src/components/LogOutBtn/LogOutBtn'
import style from './header.module.scss'

export const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      setIsOpenMenu(false)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <Layout.Header className={style.header}>
      <NavLink to="/orders">
        <div className={style.header__logo}>
          <img src={logo} alt="Botanic" />
        </div>
      </NavLink>
      <div className={style.header__menu}>
        <Menu />
      </div>
      <LogOutBtn className={style.header__logout} />
      <button type="button" className={style.header__burger} onClick={() => setIsOpenMenu(true)}>
        <Burger />
        <span>Меню</span>
      </button>

      <Drawer
        className={style.hamburgermenu}
        styles={{
          wrapper: { boxShadow: 'none' },
          body: { padding: '18px 12px' },
        }}
        placement="right"
        width="100%"
        onClose={() => setIsOpenMenu(false)}
        open={isOpenMenu}
        title={(
          <div className={style.header__logo}>
            <img src={logo} alt="Botanic" />
          </div>
        )}
        closeIcon={(
          <>
            <CloseHamburgerMenuBtn />
            <span>Закрыть меню</span>
          </>
          )}
      >
        <Menu />
      </Drawer>
    </Layout.Header>
  )
}

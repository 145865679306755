import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedOrderListResponse } from '@src/types/orders'
import { OrderDetailResponseType, PaginatedInstallmentsListResponse } from '@src/types/order'
import { GetItemsParams } from '@src/types/api'
import { setUser, setIsInitialized } from '@src/store/ducks/app/reducer'

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    method: 'POST',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  tagTypes: ['Orders'],
  endpoints: (build) => ({
    orders: build.query<PaginatedOrderListResponse, GetItemsParams>({
      query: (params) => ({
        url: '/orders',
        params: {
          page: params.page,
          order_number: params.order_number,
          year: params.year,
          orderByDate: params.orderByDate,
          orderByStatus: params.orderByStatus,
          orderByNumber: params.orderByNumber,
        },
      }),
      //transformResponse: (response: OrdersListResponse) => {
      ////Логируем ответ
      //console.log('Orders response:', response)
      //return response //Возвращаем ответ для сохранения в состоянии
      //},
      providesTags: () => ['Orders'],
    }),
    orderDetail: build.query<OrderDetailResponseType, number>({
      query: (id) => ({
        url: `/orders/${id}`,
      }),
      providesTags: () => ['Orders'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          console.error('Error fetching user:', error)
        } finally {
          dispatch(setIsInitialized())
        }
      },
    }),
    orderInstallments: build.query<PaginatedInstallmentsListResponse, number>({
      query: (id) => ({
        url: `/installments/${id}`,
      }),
      providesTags: () => ['Orders'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          console.error('Error fetching user:', error)
        } finally {
          dispatch(setIsInitialized())
        }
      },
    }),
  }),
})

import React, { useEffect, useMemo, useState } from 'react'
import { Routes } from '@src/routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { setIsInitialized, initializeAuth, setUser } from '@src/store/ducks/app/reducer' //TORS
import { Preloader } from '@src/components/Preloader/Preloader'
import { useHistory, useLocation } from 'react-router-dom'
import { userApi } from '@src/store/services/user-service' //TORS
import { useMatchRoute } from '@src/utils/reactUtils'
import cn from 'classnames'
import './style/app.scss'
import { PageContainer } from './components/PageContainer/PageContainer'
import { selectAppIsAuth, selectAppIsInitialized } from './store/ducks/app/selectors' //TORS

const App = () => {
  const isAuth = useSelector(selectAppIsAuth)
  const isInitialized = useSelector(selectAppIsInitialized)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [delayedInit, setDelayedInit] = useState(false)
  const { data: user, isLoading } = userApi.useMeQuery(undefined, {
    skip: !isAuth,
  })

  type AppProps = {
    className?: string;
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  useEffect(() => {
    dispatch(initializeAuth())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      dispatch(setIsInitialized())
    }
  }, [dispatch])

  const pageContainerWoHeaderClass = useMatchRoute({
    path: '/сlarify-user-info',
    className: 'App_withoutHeader',
  })

  return (
    <div style={{ height: '100dvh' }}>
      <Preloader loading={!isInitialized} center>
        <div className={cn('App', pageContainerWoHeaderClass)}>
          <PageContainer>
            <Routes />
          </PageContainer>
        </div>
      </Preloader>
    </div>
  )
}

export default App

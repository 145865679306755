import { FC, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/store/services/auth-service'
import { selectAppPhoneNumber } from '@src/store/ducks/app/selectors'
import { setUserId } from '@src/store/ducks/app/reducer'
import { Button } from 'antd'
import { SendOutlined, UserAddOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AuthContainer } from '@src/components/Auth/AuthContainer/AuthContainer'
import style from './advancedAuthorization.module.scss'

export const AdvancedAuthorization: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [authorization, { isLoading }] = authApi.useAuthorizationMutation()
  const history = useHistory()
  const authorizationPhone = useSelector(selectAppPhoneNumber)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
    })
  ), [])

  const initialValues = useMemo(() => ({
    name: '',
    phone: authorizationPhone,
  }), [authorizationPhone])

  const onSubmit = async (value: typeof initialValues) => {
    console.log('Submitting form:', value)
    const result = await authorization(value)
    //if ('data' in result && result.data?.success) {
    if ('data' in result) {
      const { user_id } = result.data
      user_id && dispatch(setUserId(result.data.user_id))
      history.push('/login')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>, submitForm: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitForm()
    }
  }

  return (
    <AuthContainer title="Авторизация" showBackBtn>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <Form onKeyDown={(event) => handleKeyDown(event, submitForm)}>
            <FieldFormik name="name" placeholder="Фамилия" fieldTitle="Введите Вашу фамилию" />

            <div className={style.actions}>
              <Button type="primary" htmlType="submit" size="large" className="btn__login" icon={<SendOutlined />} loading={isLoading}>
                Подтвердить данные
              </Button>

              <div className={style.support}>
                <span className={style.support__title}>Проблемы с авторизацией?</span>
                <span className={style.support__info}>
                  Свяжитесь по номеру
                  <a className="main-link" href="tel:+375296661177">+375 (29) 666 11 77</a>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  )
}

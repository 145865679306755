import { FC, useMemo, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/store/services/auth-service'
import { setPhoneNumber, setUserId } from '@src/store/ducks/app/reducer'
import { Button, Checkbox } from 'antd'
import type { CheckboxProps } from 'antd'
import { UserSwitchOutlined, UserAddOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AuthContainer } from '@src/components/Auth/AuthContainer/AuthContainer'
import cn from 'classnames'
import style from './registration.module.scss'

export const Registration: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [registration, { isLoading }] = authApi.useRegistrationMutation()
  const history = useHistory()

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [error, setError] = useState<string|null>(null)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^\+375\d{9}$/, 'Некорректный номер')
        .length(13, 'Некорректный номер')
        .required('Обязательное поле'),
    })
  ), [])

  const initialValues = useMemo(() => ({
    phone: '',
  }), [])

  const onSubmit = async (value: typeof initialValues) => {
    //setIsFormSubmitted(true)
    if (!isCheckboxChecked) {
      return
    }

    const result = await registration(value)

    //Это просто бред какой-то с типами данных, но я просто не смог достать иначе текст ошибки
    if ('error' in result) {
      console.log('init1')
      if ('data' in result.error) {
        if (result.error.data && typeof result.error.data === 'object' && 'error' in result.error.data) {
          const errorMessage = result.error.data.error
          if (errorMessage && typeof errorMessage === 'string') {
            console.log('errorMessage', errorMessage)
            setError(errorMessage)
          }
        }
      }
    }

    if ('data' in result) {
      console.log('cucsesf')
      setError(null)
      const { user_id, message } = result.data
      dispatch(setPhoneNumber(value.phone))
      user_id && dispatch(setUserId(result.data.user_id))
      if (message) {
        history.push('/login')
      } else {
        history.push('/login', { isRegistration: true })
      }
    }
  }

  const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
    setIsCheckboxChecked(e.target.checked)
    if (e.target.checked) {
      setIsFormSubmitted(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>, submitForm: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitForm()
    }
  }

  return (
    <AuthContainer title="Регистрация" showBackBtn>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <Form onKeyDown={(event) => handleKeyDown(event, submitForm)}>
            <FieldFormik type="phone" name="phone" placeholder="+375 (XX) XXX-XX-XX" fieldTitle="Ваш номер телефона" />

            {error && <div className={style.errorContainer}>{error}</div>}

            <div className={style.actions}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="btn__login"
                disabled={!isCheckboxChecked}
                icon={<UserAddOutlined />}
                onClick={() => setIsFormSubmitted(true)}
                loading={isLoading}
              >
                Зарегистрироваться
              </Button>

              <div className={style.subtitle}>
                Уже существует аккаунт?
              </div>

              <NavLink to="/authorization">
                <Button type="link" size="large" className="btn__link" icon={<UserSwitchOutlined />}>Авторизация</Button>
              </NavLink>

              <div className={style.support}>
                <Checkbox onChange={onCheckboxChange} className={cn('customAntCheckbox', isFormSubmitted && !isCheckboxChecked && style.error)}>
                  <span>Согласен с условиями</span>
                  <a href="https://ags-agro.by/o-nas#oferta" className="main-link main-link--full-green" target="_blank" rel="noopener noreferrer">
                    Публичной оферты
                  </a>
                  <span>и</span>
                  <a href="https://ags-agro.by/o-nas#personaldata" className="main-link main-link--full-green" target="_blank" rel="noopener noreferrer">
                    Положением о персональных данных
                  </a>
                </Checkbox>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  )
}

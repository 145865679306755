export const theme = {
  components: {
    Tabs: {
      cardBg: '#fff',
      cardGutter: 0,
      cardPadding: '8px 16px',
      itemSelectedColor: '#fff',
    },
    Input: {
      hoverBorderColor: '#389E0D',
      activeBorderColor: '#389E0D',
      activeShadow: '0 0 0 2px rgba(0, 60, 5, 0.22)',
    },
    Button: {
      defaultShadow: 'unset',
      primaryShadow: 'unset',
      defaultActiveBg: '#389E0D',
      defaultActiveBorderColor: '#389E0D',
      defaultActiveColor: '#389E0D',
      textTextActiveColor: '#fff',
    },
  },
  token: {
    colorPrimary: '#389E0D',
    colorPrimaryActive: '#389E0D',
    colorPrimaryHover: '#389E0D',
    colorPrimaryBgHover: '#389E0D',
    controlItemBgActiveHover: '#389E0D',
    controlItemBgActive: '#389E0D',
    borderRadius: 2,
    borderRadiusLG: 2,
    borderRadiusSM: 2,
    boxShadow: 'none',
    lineWidth: 1,
    contentLineHeightLG: 1.4,
    fontFamily: 'Roboto, "Open Sans", Arial, sans-serif',
  },
}

import { FC } from 'react'
import cn from 'classnames'
import style from './title.module.scss'

type TitleProps = {
  text: string
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}

export const Title: FC<TitleProps> = ({ text, tag: Tag, className }) => (
  <Tag className={cn(style.title, className)}>{text}</Tag>
)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState = {
  isInitialized: false,
  isAuth: false,
  isPhoneChanged: false,
  needClarifyUserData: false,
  phoneNumber: '',
  userId: 0,
  userName: '',
  userEmail: '',
  user: {
    ID: 0,
    Email: '',
    Client: '',
    PhoneLK: '',
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitialized(state) {
      state.isInitialized = true
    },
    setIsLoading(state) {
      state.isInitialized = false
    },
    setIsAuth(state, { payload }: PayloadAction<boolean>) {
      state.isAuth = payload
    },
    setNeedClarifyUserData(state, action: PayloadAction<boolean>) {
      state.needClarifyUserData = action.payload
    },
    setUser(state, { payload }: PayloadAction<typeof initialState.user>) {
      state.user = payload
    },
    setIsPhoneChanged(state, { payload }: PayloadAction<boolean>) {
      state.isPhoneChanged = payload
    },
    setPhoneNumber(state, { payload }: PayloadAction<string>) {
      state.phoneNumber = payload
    },
    setUserId(state, { payload }: PayloadAction<number>) {
      state.userId = payload
    },
    logoutAC(state) {
      state.isAuth = false
      state.phoneNumber = ''
      localStorage.removeItem('token')
      localStorage.removeItem('phoneRequest')
    },
    initializeAuth(state) {
      const token = localStorage.getItem('token')
      const phoneRequest = localStorage.getItem('phoneRequest')
      state.isAuth = !!token
      state.isPhoneChanged = phoneRequest === 'sent'
    },
    //resetStore: () => initialState,
  },
})

export type AppStateType = typeof initialState

export const {
  setIsInitialized,
  setIsAuth,
  setNeedClarifyUserData,
  setUser,
  setIsLoading,
  setIsPhoneChanged,
  setPhoneNumber,
  setUserId,
  logoutAC,
  initializeAuth,
  //resetStore,
} = appSlice.actions

export const appReducer = appSlice.reducer

import { NavLink } from 'react-router-dom'
import { LogOutBtn } from '@src/components/LogOutBtn/LogOutBtn'
import style from './menu.module.scss'

export const Menu = () => (
  <div className={style.menu__wrapper}>
    <nav className={style.menu}>
      <div className={style.menu__mobTitle}>
        Меню
      </div>
      <ul className={style.menu__list}>
        <li className={style.menu__item}>
          <NavLink to="/orders" className={style.menu__link} activeClassName={style.menu__link_active} exact>
            История заказов
          </NavLink>
        </li>
        <li className={style.menu__item}>
          <NavLink to="/profile" className={style.menu__link} activeClassName={style.menu__link_active} exact>
            Личные данные
          </NavLink>
        </li>
      </ul>
    </nav>
    <div className={style.menu__bottom}>
      <LogOutBtn className={style.menu__logout} />
    </div>
  </div>
)

import { useCallback, useMemo, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import type { GetProps } from 'antd'
import { Table, Button, TablePaginationConfig, Tabs, Input, Empty } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource, Key } from 'antd/es/table/interface'
import useQuery from '@src/hooks/useQuery'
import { OrderListItemType } from '@src/types/orders'
import { PageHeader } from '@src/components/PageHeader/PageHeader'
import { StatusBadge } from '@src/components/StatusBadge/StatusBadge'
import { formatCurrency } from '@src/utils/utils'
import cn from 'classnames'
import { ordersApi } from '@src/store/services/orders-service'
import style from './orders.module.scss'

const pageHeaderData = {
  title: 'История заказов',
  description: 'Если необходимо дополнительное текстовое пояснение',
}

const initialFilterTabs = [
  { label: '2024', key: 'current' },
  { label: '2023', key: 'last' },
  { label: 'Архив', key: 'previous' },
]

type SearchProps = GetProps<typeof Input.Search>;
const { Search } = Input

const getInstallmentsUrl = (record: OrderListItemType): string | null => {
  if (record.Pavilion === 1) {
    return `/installments-stages/${record.ID}`
  }
  if (record.KolPlateg && record.KolPlateg > 1) {
    return `/installments/${record.ID}`
  }
  return null
}

export const Orders = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pageQuery = useQuery('page')
  const [activeKey, setActiveKey] = useState('999')
  const [searchValue, setSearchValue] = useState<string>('')
  const [items, setItems] = useState(initialFilterTabs)

  const initialFilters: FiltersType = {
    page: Number(searchParams.get('page')) || 1,
    year: searchParams.get('year') as 'current' | 'last' | 'previous' || undefined,
    order_number: searchParams.get('order_number') || undefined,
    orderByDate: searchParams.get('orderByDate') as 'asc' | 'desc' || undefined,
    orderByNumber: searchParams.get('orderByNumber') as 'asc' | 'desc' || undefined,
    orderByStatus: searchParams.get('orderByStatus') as 'asc' | 'desc' || undefined,
  }

  type FiltersType = {
    page: number;
    year?: 'current' | 'last' | 'previous';
    order_number?: string;
    orderByDate?: 'asc' | 'desc';
    orderByNumber?: 'asc' | 'desc';
    orderByStatus?: 'asc' | 'desc';
  };

  const [filters, setFilters] = useState<FiltersType>(initialFilters)
  const { data: response, status } = ordersApi.useOrdersQuery(filters)
  const [dataSource, setDataSource] = useState<OrderListItemType[]>([])

  useEffect(() => {
    if (status === 'fulfilled' && response) {
      console.log('Fetched orders data:', response)
      setDataSource(response.data.data)
    } else if (status === 'pending') {
      //setDataSource([]) //Очистка данных при ожидании (опционально)
    }
  }, [status, response])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const year = searchParams.get('year') || '999'
    const orderNumber = searchParams.get('order_number') || ''

    setActiveKey(year)
    setSearchValue(orderNumber)
    setFilters((prev) => ({
      ...prev,
      order_number: orderNumber,
    }))
  }, [location.search])

  useEffect(() => {
    const params = new URLSearchParams()
    Object.keys(filters).forEach((key) => {
      const value = filters[key as keyof FiltersType]

      if (value) {
        params.set(key, String(value))
      }
    })
    history.replace({ search: params.toString() })
  }, [filters, history])

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<OrderListItemType> | SorterResult<OrderListItemType>[],
      extra: TableCurrentDataSource<OrderListItemType>,
    ) => {
      setFilters((prev) => {
        const newFilters: FiltersType = {
          page: pagination.current || 1,
          year: prev.year,
          order_number: prev.order_number,
          orderByDate: prev.orderByDate,
          orderByNumber: prev.orderByNumber,
          orderByStatus: prev.orderByStatus,
        }

        if (!Array.isArray(sorter)) {
          if (sorter.field === 'DocumentDate') {
            newFilters.orderByDate = sorter.order === 'ascend' ? 'asc' : 'desc'
          } else if (sorter.field === 'ContractNumber') {
            newFilters.orderByNumber = sorter.order === 'ascend' ? 'asc' : 'desc'
          } else if (sorter.field === 'SaleState') {
            newFilters.orderByStatus = sorter.order === 'ascend' ? 'asc' : 'desc'
          }
        }

        return newFilters
      })
    },
    [],
  )

  const renderMobileCard = useCallback((record: OrderListItemType) => (
    <div className={style.mobileCard}>
      <div className={style.mobileCard__header}>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <span>Дата заказа</span>
          <span>
            <strong>{new Date(record.DocumentDate).toLocaleDateString()}</strong>
          </span>
        </div>
        <div className={cn(style.mobileCard__item, style.mobileCard__item_vertical)}>
          <StatusBadge status={record.SaleState.toString()} />
        </div>
      </div>
      <div className={style.mobileCard__main}>
        <div className={style.mobileCard__item}>
          <span>Номер заказа</span>
          <span>
            <strong>{record.ContractNumber}</strong>
          </span>
        </div>
        <div className={style.mobileCard__item}>
          <span>Позиции</span>
          <span>
            <strong>{record.products_count}</strong>
          </span>
        </div>
        <div className={style.mobileCard__item}>
          <span>Сумма</span>
          <span>
            <strong>{formatCurrency(record.AmountWithNds)}</strong>
          </span>
        </div>
      </div>
      <div className={style.mobileCard__footer}>
        <Button type="primary" className={cn(style.table__btn, 'btn__main')} onClick={() => history.push(`/orders/${record.ID}`)}>
          Детали заказа
        </Button>
        {/*eslint-disable-next-line no-nested-ternary*/}
        {record.Pavilion === 1 ? (
          <Button
            type="primary"
            className={cn(style.table__btn, 'btn__secondary btn__secondary_greenBorder')}
            onClick={() => history.push(`/installments-stages/${record.ID}`, { contractNumber: record.ContractNumber })}
          >
            Детали рассрочки
          </Button>
        ) : record.KolPlateg && record.KolPlateg > 1 ? (
          <Button
            type="primary"
            className={cn(style.table__btn, 'btn__secondary btn__secondary_greenBorder')}
            onClick={() => history.push(`/installments/${record.ID}`, { contractNumber: record.ContractNumber })}
          >
            Детали рассрочки
          </Button>
        ) : null}
      </div>
    </div>
  ), [history])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: 'Дата заказа',
      dataIndex: 'DocumentDate',
      key: 'DocumentDate',
      render: (date) => <strong>{new Date(date).toLocaleDateString()}</strong>,
      width: 137,
      align: 'center',
      responsive: ['sm'],
      sorter: true,
    },
    {
      title: 'Статус заказа',
      dataIndex: 'SaleState',
      key: 'SaleState',
      render: (status) => <StatusBadge status={status} />,
      width: 170,
      responsive: ['sm'],
      sorter: true,
    },
    {
      title: 'Номер заказа',
      dataIndex: 'ContractNumber',
      key: 'ContractNumber',
      responsive: ['sm'],
      sorter: true,
    },
    {
      title: 'Позиции',
      dataIndex: 'products_count',
      key: 'products_count',
      width: 90,
      align: 'center',
      responsive: ['sm'],
    },
    {
      title: 'Сумма заказа',
      dataIndex: 'AmountWithNds',
      key: 'AmountWithNds',
      width: 130,
      align: 'center',
      responsive: ['sm'],
      render: (AmountWithNds) => formatCurrency(AmountWithNds),
    },
    {
      title: 'Мобильный вид',
      key: 'mobile',
      responsive: ['xs'],
      render: (record) => renderMobileCard(record),
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 200,
      align: 'left',
      responsive: ['sm'],
      render: (text, record) => (
        <>
          <Button
            type="primary"
            className={cn(style.table__btn, 'btn__main')}
            onClick={() => history.push(`/orders/${record.ID}`)}
          >
            Детали
          </Button>
          {/*eslint-disable-next-line no-nested-ternary*/}
          {record.Pavilion === 1 ? (
            <Button
              type="primary"
              className={cn(style.table__btn, 'btn__secondary btn__secondary_greenBorder')}
              onClick={() => history.push(`/installments-stages/${record.ID}`, { contractNumber: record.ContractNumber })}
            >
              Рассрочкa
            </Button>
          ) : record.KolPlateg && record.KolPlateg > 1 ? (
            <Button
              type="primary"
              className={cn(style.table__btn, 'btn__secondary btn__secondary_greenBorder')}
              onClick={() => history.push(`/installments/${record.ID}`, { contractNumber: record.ContractNumber })}
            >
              Рассрочкa
            </Button>
          ) : null}
        </>
      ),
    },
  //], [onCell, history])
  ], [history])

  const onChange = (newActiveKey: string) => {
    if (['current', 'last', 'previous'].includes(newActiveKey)) {
      setFilters((prev) => ({
        ...prev,
        year: newActiveKey as 'current' | 'last' | 'previous',
        page: 1,
      }))
      setActiveKey(newActiveKey)
    }
  }

  const onClear = useCallback(() => {
    setSearchValue('')
    setFilters((prev) => ({
      ...prev,
      order_number: '' || undefined,
      page: 1,
    }))
  }, [])

  const onSearch = useCallback((value: string) => {
    if (!value) {
      onClear()
    } else {
      const trimmedValue = value.trim()
      if (trimmedValue) {
        setSearchValue(trimmedValue)
        setFilters((prev) => ({
          ...prev,
          order_number: trimmedValue || undefined,
          page: 1,
        }))
      }
    }
  }, [])

  return (
    <div className={style.orders__wrapper}>
      <PageHeader title={pageHeaderData.title} description={pageHeaderData.description} />
      <div className={style.filtersBar}>
        <div className={style.filtersBar__tabs}>
          <Tabs
            onChange={onChange}
            type="card"
            activeKey={activeKey}
            items={initialFilterTabs}
          />
        </div>
        <div className={style.filtersBar__searchContaier}>
          <Search className="customAntSeatchInput" placeholder="Введите номер заказа" onSearch={onSearch} allowClear defaultValue={filters.order_number} />
        </div>
      </div>
      <Table<OrderListItemType>
        dataSource={dataSource}
        columns={columns}
        rowKey="ID"
        loading={status === 'pending'}
        className={style.table}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Нет доступных данных"
            />

          ),
          triggerDesc: 'по убыванию',
          triggerAsc: 'по возрастанию',
          cancelSort: 'без сортировки',
        }}
        pagination={{
          total: response?.data.total,
          current: filters.page,
          pageSize: response?.data.per_page,
          showSizeChanger: false,
          hideOnSinglePage: !response?.data.next_page_url,
        }}
      />
    </div>
  )
}

import { FC, useEffect, useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader } from '@src/components/PageHeader/PageHeader'
import { MailOutlined, PhoneOutlined, UserOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { NavLink, useHistory } from 'react-router-dom'
import { logoutAC, setIsAuth } from '@src/store/ducks/app/reducer'
import { formatPhoneNumber } from '@src/utils/utils'
import { selectAppUser } from '@src/store/ducks/app/selectors'
import { userApi } from '@src/store/services/user-service'
import { authApi } from '@src/store/services/auth-service'
import style from './profile.module.scss'

const pageHeaderData = {
  title: 'Личные данные',
  description: 'Если необходимо дополнительное текстовое пояснение',
}

const userColumnTitles: Record<string, string> = {
  Email: 'Электронная почта',
  Client: 'Ваше ФИО',
  PhoneLK: 'Номер телефона',
}

const getFieldIcon = (key: string) => {
  switch (key) {
    case 'Email':
      return <MailOutlined />
    case 'PhoneLK':
      return <PhoneOutlined />
    default:
      return <UserOutlined />
  }
}

const getFieldTitle = (key: string) => userColumnTitles[`${key}`]

export const Profile:FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAppUser)
  const [deleteUser, { isLoading }] = userApi.useDeleteMutation()
  const [logout] = authApi.useLogoutMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const [isUserEmpty, setIsUserEmpty] = useState(true)

  useEffect(() => {
    const userIsEmpty = user.ID === 0
    setIsUserEmpty(userIsEmpty)
  }, [user, dispatch])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    await deleteUser()
    dispatch(userApi.util.resetApiState())
    dispatch(logoutAC())
    setIsModalOpen(false)
    history.push('/authorization')
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={style.profile__wrapper}>
      <PageHeader title={pageHeaderData.title} description={pageHeaderData.description} />
      {isUserEmpty ? (
        <div className={style.profile__preloaderWrapper}>
          <Spin size="large" />
        </div>
      ) : (
        <ul className={style.profile__info}>
          {Object.entries(user)
            .filter(([key]) => key !== 'ID')
            .map(([key, value]) => (
              <li key={key} className={style.infoItem}>
                <div className={style.infoItem__label}>
                  {getFieldIcon(key)}
                  {getFieldTitle(key)}
                </div>
                {value
                  ? <div className={style.infoItem__value}>{key === 'PhoneLK' ? formatPhoneNumber(String(value)) : value}</div>
                  : <div className={style.infoItem__value}>Данные отсутствуют</div>}
              </li>
            ))}
        </ul>
      )}

      <div className={style.profile__btns}>
        <NavLink to="/edit-profile">
          <Button type="primary" size="large" className="btn__main" icon={<EditOutlined />}>Редактировать данные</Button>
        </NavLink>
        <Button onClick={showModal} type="primary" size="large" danger icon={<DeleteOutlined />} loading={isLoading}>
          Удалить аккаунт
        </Button>
      </div>
      <Modal
        title={(
          <span>
            <ExclamationCircleOutlined style={{ color: '#faad14', marginRight: 8 }} />
            Обратите внимание
          </span>
      )}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Подтвердить"
        cancelText="Отмена"
        okButtonProps={{ danger: true }}
        centered
        className="customAntModal"
      >
        <p>Удаление аккаунта приведет к потере всех данных и истории заказов.</p>
      </Modal>
    </div>
  )
}

import { FC, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/store/services/auth-service'
import { userApi } from '@src/store/services/user-service'
import { setIsAuth, setNeedClarifyUserData } from '@src/store/ducks/app/reducer'
import { selectAppIsAuth, selectAppPhoneNumber, selectAppUserId } from '@src/store/ducks/app/selectors'
import { Button } from 'antd'
import { SendOutlined, RedoOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AuthContainer } from '@src/components/Auth/AuthContainer/AuthContainer'
import style from './login.module.scss'

const getResendMessage = (timer: number) =>
  (timer > 0
    ? `Вы можете запросить повторный код через ${timer} секунд.`
    : 'Вы можете запросить повторный код')

export const Login: FC = () => {
  const [login, { isLoading: isLoginLoading }] = authApi.useLoginMutation()
  const [registration, { isLoading: isRegistrationLoading }] = authApi.useRegistrationMutation()
  const [authorization, { isLoading: isAuthorizationLoading }] = authApi.useAuthorizationMutation()
  const { refetch: fetchUserData, isFetching: isUserLoading } = userApi.useMeQuery()
  const dispatch = useDispatch<AppDispatch>()
  const isAuth = useSelector(selectAppIsAuth)
  const authorizationUserId = useSelector(selectAppUserId)
  const location = useLocation<{ isRegistration?: boolean }>()
  const isRegistration = location.state?.isRegistration || false
  const authorizationPhone = useSelector(selectAppPhoneNumber)

  const [isCodeResendButtonVisible, setCodeResendButtonVisible] = useState(false)
  const [resendTimer, setResendTimer] = useState(60)
  const history = useHistory()

  //resend code btn logic
  const handleResendCode = async () => {
    console.log('Код отправлен повторно')

    setResendTimer(60)
    setCodeResendButtonVisible(false)

    const countdown = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown)
          setCodeResendButtonVisible(true)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    let result
    const requestBody = {
      phone: authorizationPhone,
    }

    if (isRegistration) {
      result = await registration(requestBody)
    } else {
      result = await authorization(requestBody)
    }
  }

  useEffect(() => {
    if (resendTimer > 0) {
      const countdown = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown)
            setCodeResendButtonVisible(true)
            return 0
          }
          return prev - 1
        })
      }, 1000)
      return () => clearInterval(countdown)
    }
  }, [resendTimer])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      sms_token: Yup.string()
        .matches(/^\d{4}$/, 'Код должен содержать только 4 цифры')
        .required('Обязательное поле'),
    })
  ), [])

  const initialValues = useMemo(() => ({
    sms_token: null,
    user_id: authorizationUserId,
    registration: isRegistration,
  }), [authorizationUserId, isRegistration])

  const onSubmit = async (value: typeof initialValues) => {
    console.log('onSubmit FIRED')
    const result = await login(value)
    if ('data' in result) {
      localStorage.setItem('token', result.data.api_token)
      const userResult = await fetchUserData()

      if (userResult.data) {
        const userData = userResult.data.data
        const isUserDataComplete = Boolean(userData.Client && userData.Email)
        if (!isUserDataComplete) {
          dispatch(setNeedClarifyUserData(true))
          dispatch(setIsAuth(true))
          //history.push('/сlarify-user-info', { needClarifyUserData: true })
          history.push('/сlarify-user-info')
        } else {
          dispatch(setIsAuth(true))
          history.push('/orders')
        }
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>, submitForm: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitForm()
    }
  }

  return (
    <AuthContainer title={isRegistration ? 'Регистрация' : 'Авторизация'} showBackBtn>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <Form onKeyDown={(event) => handleKeyDown(event, submitForm)}>
            <FieldFormik name="sms_token" placeholder="0000" fieldTitle="Введите sms-код" maxLength={4} allowOnlyNumbers inputTextCentered />

            <div className={style.actions}>
              <Button type="primary" htmlType="submit" size="large" className="btn__login" icon={<SendOutlined />} loading={isLoginLoading}>
                Отправить код
              </Button>

              <div className={style.support}>
                <div className={style.subtitle}>
                  {getResendMessage(resendTimer)}
                </div>

                <Button
                  type="link"
                  size="large"
                  className="btn__link"
                  icon={<RedoOutlined spin={resendTimer > 0} />}
                  disabled={!isCodeResendButtonVisible}
                  onClick={handleResendCode}
                >
                  Выслать код повторно
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  )
}

//hook to add specific class base on router location
import { useLocation } from 'react-router-dom'

type UseMatchRouteProps = {
  path: string;
  className: string;
};

export const useMatchRoute = ({ path, className }: UseMatchRouteProps) => {
  const location = useLocation()
  return location.pathname === path ? className : ''
}

import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { authApi } from '@src/store/services/auth-service'
import { ordersApi } from '@src/store/services/orders-service'
import { userApi } from '@src/store/services/user-service'
import { appReducer, setIsAuth } from '@src/store/ducks/app/reducer'

//Это пользовательский middleware для обработки ошибок RTK Query. Он перехватывает экшены, которые отклонены (isRejectedWithValue).
//Если статус ошибки равен 401, это означает, что пользователь неавторизован, и токен удаляется из localStorage. Затем вызывается экшен setIsAuth(false), чтобы снять флаг авторизации.
//Если статус ошибки не равен 404 или 422, вызывается уведомление об ошибке с использованием notification.error.
const excludedEndpoints = ['registration', 'authorization'];

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const requestEndpoint = action.meta?.arg?.endpointName;
    console.log(requestEndpoint)

    // Check if the request's endpoint is in the excluded list
    const isExcluded = excludedEndpoints.some((endpoint) => requestEndpoint?.includes(endpoint));

    if (!isExcluded) {
      if (action.payload.status === 401) {
        localStorage.removeItem('token');
        api.dispatch(setIsAuth(false));
      } else if (action.payload.status !== 404 && action.payload.status !== 422) {
        notification.error({
          message: '',
          description: action.payload.data?.error || action.payload.data?.message || action.payload.error || action.payload.error?.error || 'Что-то пошло не так, попробуйте ещё раз',
          className: 'customAntNotification',
        });
      }
    }
  }

  return next(action);
};

//Это middleware для успешных запросов RTK Query. Оно перехватывает экшены, которые завершились успешно.
export const rtkQuerySuccessLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.type.endsWith('/fulfilled')) {
    const endpointName = action.meta.arg?.endpointName
    switch (endpointName) {
      case 'registration':
        if (action.payload.message) {
          notification.success({
            message: '',
            description: action.payload.message,
            className: 'customAntNotification',
          })
        }
        break
      case 'update':
        if (action.payload.message) {
          notification.success({
            message: '',
            description: action.payload.message,
            className: 'customAntNotification',
          })
        }
        break
      case 'updatePhone':
        if (action.payload.message) {
          notification.success({
            message: '',
            description: action.payload.message,
            className: 'customAntNotification',
          })
        }
        break
        //case 'login':
        //notification.success({
        //message: '',
        //description: 'Добро пожаловать в личный кабинет',
        //})
        //break
      default:
        break
    }
  }

  return next(action)
}

const combinedReducer = combineReducers({
  app: appReducer,
  [authApi.reducerPath]: authApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
})

const middleware = [
  authApi.middleware,
  ordersApi.middleware,
  userApi.middleware,
  rtkQueryErrorLogger,
  rtkQuerySuccessLogger,
]

//reset store on logout
const rootReducer = (state: any, action: any) => {
  let tmpState = state
  if (action.type === 'app/logoutAC') {
    tmpState = undefined
  }
  return combinedReducer(tmpState, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import { FC } from 'react'
import { Badge } from 'antd'

//Объект, содержащий возможные статусы для заказа
const orderStatusMap: Record<string, string> = {
  Отмена: 'red',
  Отказ: 'orange',
  Неподтвержден: 'yellow',
  Оформлен: 'blue',
  Отгружен: 'green',
  'В резерве': 'purple',
  'В доставке': 'cyan',
}

//Объект, содержащий возможные статусы для рассрочки
const installmentStatusMap: Record<string, string> = {
  'Не оплачено': 'red',
  Ожидание: 'blue',
  'Частично оплачено': 'yellow',
  Оплачено: 'green',
}

type StatusType = 'order' | 'installmentStages' | 'installment'

interface StatusBadgeProps {
  status?: string
  type?: StatusType
  installmentNumbers?: any[]
}

export const StatusBadge: FC<StatusBadgeProps> = ({ status, type = 'order', installmentNumbers }) => {
  let badgeStatus: string

  //Функция для проверки, можно ли привести значение к числу
  const isNumberOrConvertible = (value: any): boolean => !isNaN(value) && value !== null && value !== 'null' && value !== 'undefined' && value !== ''

  if (installmentNumbers && installmentNumbers.length >= 2) {
    const [totalAmount, debt] = installmentNumbers
    console.log('totalAmount:', totalAmount, 'debt:', debt)

    if (!isNumberOrConvertible(totalAmount) || !isNumberOrConvertible(debt)) {
      badgeStatus = 'Неизвестно'
    } else {
      switch (true) {
        case debt === 0:
          badgeStatus = 'Оплачено'
          break
        case totalAmount === debt:
          badgeStatus = 'Не оплачено'
          break
        default:
          badgeStatus = 'Частично оплачено'
      }
    }
  } else {
    badgeStatus = 'Неизвестно'
  }

  //Если тип 'order', выбираем статус из orderStatusMap
  if (type === 'order' && status) {
    badgeStatus = status
  }

  //Определяем цвет для статуса
  const statusMap = type === 'order' ? orderStatusMap : installmentStatusMap
  const color = statusMap[badgeStatus] || 'gray'
  const title = color === 'gray' ? 'Неизвестный статус' : badgeStatus

  return (
    <Badge color={color} text={title} />
  )
}

export default StatusBadge

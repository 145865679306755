import { FC, ReactNode } from 'react'
//eslint-disable-next-line import/no-extraneous-dependencies
import { RouteProps } from 'react-router'
import { useSelector } from 'react-redux'
import { selectAppIsAuth, selectAppUser } from '@src/store/ducks/app/selectors'
import { Redirect, Route } from 'react-router-dom'

type PrivateRouteProps = {
  children: ReactNode
  withoutHeader?: boolean,
} & RouteProps

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const isAuth = useSelector(selectAppIsAuth)
  //const user = useSelector(selectAppUser)

  //const isUserDataComplete = user.Client && user.Email

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuth) {
          return <Redirect to={{ pathname: '/authorization', state: { from: props.location } }} />
        }

        //if (!isUserDataComplete) {
        //return <Redirect to={{ pathname: '/registration' }} />
        //}

        //Если все условия выполнены, возвращаем детей (дочерние компоненты)
        return children
      }}
    />
  )
}

//return (
//<Route
//{...rest}
//render={(props) => {
//if (!isAuth) {
//return <Redirect to={{ pathname: '/authorization', state: { from: props.location } }} />
//}
//
//return children
//}}
///>
//)

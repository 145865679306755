import React, { FC } from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppIsAuth, selectNeedClarifyUserData } from '@src/store/ducks/app/selectors'

type PublicRouteProps = RouteProps & {
  children: React.ReactNode
}

export const PublicRoute: FC<PublicRouteProps> = ({ children, ...rest }) => {
  const isAuth = useSelector(selectAppIsAuth)
  const location = useLocation<{ isRegistration?: boolean }>()
  //const location2 = useLocation<{ needClarifyUserData?: boolean }>()
  const isRegistration = location.state?.isRegistration || false
  //const needClarifyUserData = location.state?.isRegistration || false
  const needClarifyUserData = useSelector(selectNeedClarifyUserData)

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuth && !needClarifyUserData) {
          return <Redirect to="/orders" />
        }
        return children
      }}
    />
  )
}
//((isAuth && !isRegistration && !needClarifyUserData) ? <Redirect to="/orders" /> : children)}

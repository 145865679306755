import { FC } from 'react'
import { Title } from '../Title/Title'
import { BackBtn } from '../BackBtn/BackBtn'
import style from './pageHeader.module.scss'

type PageHeaderProps = {
  title?: string,
  description?: string,
  showBackBtn?: boolean
}

export const PageHeader: FC<PageHeaderProps> = ({ title, description, showBackBtn }) => (
  <div className={style.pageHeader}>
    {showBackBtn && <BackBtn className={style.pageHeader__backBtn} />}
    {title && <Title text={title} tag="h1" className={style.pageHeader__title} />}
    {description && <p className={style.pageHeader__description}>{description}</p>}
  </div>
)

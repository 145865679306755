import { Button, Result } from 'antd'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppIsAuth } from '@src/store/ducks/app/selectors'

export const Page404 = () => {
  const isAuth = useSelector(selectAppIsAuth)

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Извините, мы не нашли запрашиваемую страницу"
        extra={(
          <NavLink to={isAuth ? 'orders' : 'authorization'}>
            <Button type="primary">Вернуться домой</Button>
          </NavLink>
      )}
      />
    </div>
  )
}

import { FC, ReactNode } from 'react'
import cn from 'classnames'
import authLogo from '@src/assets/logo.png'
import style from './authContainer.module.scss'
import { Title } from '../../Title/Title'
import { BackBtn } from '../../BackBtn/BackBtn'

type AuthContainerProps = {
  children: ReactNode,
  title: string,
  pageClass?: string,
  showBackBtn?: boolean;
}

export const AuthContainer: FC<AuthContainerProps> = ({ children, title, pageClass, showBackBtn }) => (
  <div className={cn(style.container, pageClass)}>
    {showBackBtn && <BackBtn text="Вернуться на сайт" directLink="https://ags-agro.by/" className={style.backBtn} />}
    <div className={style.logo}>
      <img src={authLogo} alt="Botanic" />
    </div>

    <div className={style.wrap}>
      <Title text={title} tag="h1" />
      {children}
    </div>
  </div>
)
